<template>
	<body class="auth-wrapper">
		<nav class="navbar auth-bg">
			<div class="container-fluid  auth-bg p-0">
				<BLink class="brand-logo mx-auto ml-md-4 mb-0">
					<logo-sao-bento />
				</BLink>
			</div>
		</nav>

		<div class=" auth-bg auth-v2 d-flex flex-wrap ">
			<!-- Brand logo-->

			<BCol sm="5" class="d-none p-1 d-md-flex align-items-center m-auto auth-bg ">
				<div class="w-100 d-flex align-items-center justify-content-center ">
					<BImg
						fluid
						:src="require('@/assets/images/illustration/boleto-facil.svg')"
						alt="Portal do Cliente"
					/>
				</div>
			</BCol>

			<BCol sm="12" lg="5">
				<BCard>
					<BCardHeader class="d-flex justify-content-end px-0">
						<div @click="goToHome" class="d-flex mb-0 " style="cursor: pointer">
							<feather-icon icon="ChevronLeftIcon" class="my-auto" size="20" />
							<span class="my-auto font-medium-1 mr-2 mr-md-0">Voltar</span>
						</div>
					</BCardHeader>
					<BCol
						sm="8"
						v-if="step !== 3"
						class="d-flex mb-2 d-md-none align-items-center mx-auto auth-bg w-50"
					>
						<BImg
							fluid
							:src="require('@/assets/images/illustration/boleto-facil.svg')"
							alt="Portal do Cliente"
						/>
					</BCol>
					<BCardTitle class="font-weight-bold font-large-1 mb-1 mx-auto text-center">
						2ª via de Boleto
					</BCardTitle>

					<b-alert v-model="showAlert" :variant="alertVariant" dismissible class="mb-0 mt-2">
						<div class="alert-body">
							<p>{{ alertMessage }}</p>
						</div>
					</b-alert>
					<validation-observer ref="loginValidation">
						<BForm class="auth-login-form mt-2" @submit.prevent>
							<!-- CPF ou CNPJ -->
							<template v-if="step === 1">
								<BFormGroup label="CPF ou CNPJ (Somente Números)" label-for="user">
									<validation-provider #default="{ errors }" name="CPF ou CNPJ">
										<BFormInput
											id="cpf-cnpj"
											v-model="login"
											:state="errors.length > 0 ? false : null"
											name="cpf-cnpj"
											type="number"
											placeholder=""
											@keypress="filterCpfCnpjInput"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</BFormGroup>
							</template>
							<!-- Data de Nascimento  -->
							<template v-if="step === 2">
								<template v-if="isCnpj === false">
									<BFormGroup label="Data de Nascimento" label-for="user">
										<validation-provider #default="{ errors }" name="Data de Nascimento">
											<BFormInput
												id="cpf-cnpj"
												v-mask="'##/##/####'"
												v-model="dataNascimento"
												:state="errors.length > 0 ? false : null"
												name="cpf-cnpj"
												type="text"
												class="input-data"
												placeholder="dd/mm/aaaa"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</BFormGroup>
								</template>
								<template v-else>
									<BFormGroup label="Número do Contrato" label-for="user">
										<validation-provider name="contrato">
											<BFormInput
												id="contrato"
												v-model="contrato"
												name="contrato"
												type="number"
												placeholder=""
											/>
										</validation-provider>
									</BFormGroup>
								</template>
							</template>
							<!-- Data de Nascimento  -->
							<template v-if="step === 3">
								<b-alert v-model="showAlert2" variant="primary" class="mb-0 mt-2">
									<div class="alert-body">
										<p>Para proteger seus dados pessoais, algumas informações estarão ocultas.</p>
									</div>
								</b-alert>
								<BCard
									class="m-0 mb-1 p-0 border-bottom "
									v-for="fatura in faturas"
									:key="fatura.contrato"
								>
									<BCardHeader class="p-0 mb-2 d-flex flex-column align-items-start">
										<b class="mb-1">{{ fatura.empreendimento }}</b>
									</BCardHeader>
									<BCardBody class=" d-block d-lg-flex align-items-center justify-content-around">
										<div class="info-container">
											<div class="info-item">
												Contrato:<b> {{ fatura.contrato }}</b>
											</div>
											<div class="info-item">
												Parcela:<b> {{ fatura.parcela }}</b>
											</div>
											<div class="info-item">
												Valor:<b> {{ formatPriceTable(fatura.valor_aberto) }}</b>
											</div>
											<div class="info-item">
												Vencimento:<b> {{ formatDate(fatura.data_vencimento) }}</b>
											</div>
										</div>

										<div class="buttons-container mt-2 mt-lg-0 p-0">
											<b-button
												@click="download(fatura.data_vencimento, fatura.boleto_link)"
												block
												variant="primary"
											>
												<span class="mr-1">Salvar</span>
												<feather-icon class="" icon="DownloadIcon" />
											</b-button>
											<b-button @click="copyUPC(fatura.ipte)" block variant="primary">
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="25"
													height="25"
													fill="currentColor"
													class="bi bi-upc"
													viewBox="0 0 16 16"
												>
													<path
														d="M3 4.5a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-7zm3 0a.5.5 0 0 1 1 0v7a.5.5 0 0 1-1 0v-7z"
													/>
												</svg>
												Copiar Código de Barras
											</b-button>
										</div>
									</BCardBody>
								</BCard>
							</template>
							<!-- submit buttons -->
							<b-button
								v-if="step < 3"
								type="submit"
								variant="primary"
								block
								@click="nextStep()"
							>
								<b-spinner small v-if="isLoading" />
								<span v-else>Continuar</span>
							</b-button>
						</BForm>
					</validation-observer>
				</BCard>
			</BCol>
		</div>
	</body>
</template>

<script>
import {
	BLink,
	BCol,
	BCard,
	BCardHeader,
	BCardTitle,
	BForm,
	BFormGroup,
	BAlert,
	BFormInput,
	BButton,
	BCardBody,
	BSpinner,
	BImg,
} from 'bootstrap-vue'
import LogoSaoBento from '@/layouts/components/LogoSaoBento.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import loginValidation from '@/utils/loginValidation.js'
import formatValue from '@/utils/formatValue'
import services from '@/services'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
	components: {
		BLink,
		BCol,
		BCard,
		LogoSaoBento,
		BCardHeader,
		BCardTitle,
		BForm,
		ValidationProvider,
		ValidationObserver,
		BFormGroup,
		BAlert,
		BFormInput,
		BButton,
		BCardBody,
		BSpinner,
		BImg,
	},
	mixins: [formatValue],

	data() {
		return {
			isLoading: false,
			login: '',
			contrato: '',
			dataNascimento: null,
			showAlert: false,
			showAlert2: true,
			alertVariant: 'danger',
			alertMessage: '',
			step: 1,
			isCnpj: false,
			faturas: [],
			inputValue: '',
			loginValidation,
		}
	},

	methods: {
		goToHome() {
			this.$router.push('/login')
		},

		async validationForm() {
			this.$refs.loginValidation.validate().then(success => {
				if (success) {
					this.nextStep()
				}
			})
		},

		download(data, link) {
			const fileName = 'Boleto ' + data + '.pdf'
			const linkSource = link
			var FileSaver = require('file-saver')
			FileSaver.saveAs(linkSource, fileName)
		},

		copyUPC(ipte) {
			navigator.clipboard.writeText(ipte)
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'Código de barras copiado!',
					icon: 'CheckIcon',
					variant: 'success',
				},
			})
		},

		filterCpfCnpjInput(evt) {
			const pattern = /^[0-9]+$/
			if (!pattern.test(evt.key) || this.login.length >= 14) {
				evt.preventDefault()
			} else if (this.login.length >= 14) {
				return true
			}
		},

		async nextStep() {
			this.showAlert = false
			this.alertMessage = ''
			this.isLoading = true

			
			if (this.step === 1) {
				await this.handleStepOne()
			} else if (this.step === 2) {
				let data = this.dataNascimento.substr(6, 4) + "-" + this.dataNascimento.substr(3, 2) + "-" + this.dataNascimento.substr(0, 2)

				if(this.dataNascimento.substr(3, 2) > 12 || this.dataNascimento.substr(0, 2) > 31){
					this.showAlert = true
					this.alertMessage = "Data de nascimento invalida!"
				} else {
					await this.handleStepTwo(data)
				}

			}

			this.isLoading = false
		},

		async handleStepOne() {
			if (this.loginValidation.isCpfOrCnpj(this.login)) {
				try {
					await services.auth.verifyIfCpfOrCnpjExists(this.login)
					if (this.loginValidation.isCpf(this.login)) {
						this.isCnpj = false
					} else if (this.loginValidation.isCnpj(this.login)) {
						this.isCnpj = true
					}
					this.step++
				} catch {
					this.showAlert = true
					this.alertMessage = 'CPF ou CNPJ não encontrado'
				}
			} else {
				this.showAlert = true
				this.alertMessage = 'Informe um CPF ou CNPJ válido'
			}
		},

		async handleStepTwo(data) {
			try {
				if (this.isCnpj === true) {
					const faturas = await services.contract.getLatestPayments({
						cpfCnpj: this.login,
						numeroContrato: this.contrato,
					})
					this.faturas = faturas
					this.step++
				} else {
					const faturas = await services.contract.getLatestPayments({
						cpfCnpj: this.login,
						dataNascimento: data,
					})
					this.faturas = faturas
					this.step++
				}
			} catch {
				this.showAlert = true
				this.alertMessage = 'Boletos não encontrados para os dados informados!'
			}
		},
	},
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/page-auth.scss';

input::-webkit-calendar-picker-indicator{
   display: none;
}

input[type="date"]::-webkit-input-placeholder{ 
   visibility: hidden !important;
}

.input-data {
	width: 100%;
	padding: 0.438rem 1rem;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d8d6de;
	border-radius: 0.357rem
}

.brand-logo {
	svg {
		height: 80px;
		width: 170px;
	}
}

@media (max-width: 767px) {
	.border-md {
		border: none !important;
	}
}

.bg-gray {
	background-color: #9597990e;
}
</style>
